// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "./theme.scss";

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$premlogin-theme: mat.define-light-theme(
  (
    color: (
      primary: $premlogin-primary,
      accent: $premlogin-accent,
      warn: $premlogin-warn,
    ),
    //❗👀
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($premlogin-theme);

/* You can add global styles to this file, and also import other style files */

// @font-face {
//   font-family: "Noto Sans KR";
//   font-display: swap;
//   src: url("./assets/font/PingFangSCMedium.woff2");
// }

@font-face{
  font-family: "Noto Sans KR";
  src: url('./assets/font/NotoSansKR-VariableFont_wght.ttf') format('ttf');

}

@import url('https://cdn.quilljs.com/1.3.7/quill.snow.css');
@import url('https://cdn.quilljs.com/1.3.7/quill.bubble.css');

html,
body {
  color: #ffffff;
  height: 100%;
}
body {
  margin: 0;
  // font-family: "Noto Sans KR", Roboto, "Helvetica Neue", sans-serif;
  font-family: "Noto Sans KR", sans-serif !important;
  -webkit-overflow-scrolling: touch;
}

/* 隐藏Google验证的标记 */
.grecaptcha-badge {
  visibility: hidden;
}

div.container {
  // width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 600px) {
  div.container {
    width: 100%;
  }
  .app-full-dialog {
    padding: 0;
    max-width: 95vw !important;
  }
}
@media (min-width: 600px) {
  div.container {
    width: 100%;
  }
  .app-full-dialog {
    padding: 0;
    max-width: 95vw !important;
  }
}
@media (min-width: 768px) {
  div.container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  div.container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  div.container {
    width: 1200px;
  }
}

@media (min-width: 1300px) {
  div.container {
    width: 1240px;
  }
}

@media (min-width: 1400px) {
  div.container {
    width: 1360px;
  }
}

@media (min-width: 1600px) {
  div.container {
    width: 1460px;
  }
}

@media (min-width: 1900px) {
  div.container {
    width: 1720px;
  }
}

mat-form-field.full-width {
  width: 100%;
}

mat-card {
  transition: box-shadow ease 0.5s !important;
  border-radius: 15px !important;

  box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.12),
    0 10px 20px -2px rgba(0, 0, 0, 0.06) !important;
  &:hover {
    box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.37),
      0 10px 20px -2px rgba(0, 0, 0, 0.34) !important;
  }
}

a {
  text-decoration: none;
}

mat-bottom-sheet-container {
  border-radius: 27px 27px 0px 0px;
  padding: 0 !important;
}

button {
  outline: none;
}

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

.cdk-overlay-container {
  z-index: 99999999 !important;
}

.mat-mdc-radio-button {
  &.mat-accent {
    --mdc-radio-selected-focus-icon-color: #ffffff !important;
    --mdc-radio-selected-hover-icon-color: #ffffff !important;
    --mdc-radio-selected-icon-color: #ffffff !important;
    --mdc-radio-selected-pressed-icon-color: #ffffff !important;
    --mat-radio-checked-ripple-color: #ffffff !important;
  }
}

.mat-mdc-checkbox {
  
  --mdc-checkbox-selected-focus-icon-color: #B537F2 !important;
  --mdc-checkbox-selected-hover-icon-color:  #B537F2!important;
  --mdc-checkbox-selected-icon-color: #B537F2 !important;
  --mdc-checkbox-selected-pressed-icon-color: #B537F2 !important;
  --mdc-checkbox-selected-focus-state-layer-color: #B537F2 !important;
  --mdc-checkbox-selected-hover-state-layer-color: #B537F2 !important;
}

.mdc-checkbox__background {
  border-color: #ffffff67 !important;  // 修改边框颜色
  // background-color: red !important;  // 修改背景颜色
}

// :host ::ng-deep .mat-checkbox-frame {
//   border-color: #ffffff !important; // 设置未勾选状态的边框颜色
// }


.coupon-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow: visible;
    border-radius: 26px !important;
  }
}

.mat-mdc-menu-panel {
  margin-top: 5px;
  width: fit-content;
  border-radius: 25px !important;
  max-height: 500px;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  font-size: 14px !important;
  font-family: "Noto Sans KR" !important;
  font-weight: 400 !important;
  letter-spacing: 1px !important;
}

// 设置order边框
.order-dialog-container {
  border-radius: 25px; /* 设置对话框的 border-radius */
  overflow: hidden; /* 确保内容不会溢出边界 */
  border: 0px solid rgba($color: #ffffff, $alpha: 0.2);
  // border-color:rgba($color: #ffffff, $alpha: 0.2);
}

// subscription-tips-dialog边框

.subscription-tips-dialog {
  border-radius: 30px;
  overflow: hidden;
  border: 0px solid rgba($color: #ffffff, $alpha: 0.2);
  // width: 60%;
  width: fit-content;
  @media(max-width: 991px){
    // width: 80%;
  }
}

.black-background{
  background-color: #000000;
}

// .mat-dialog-container {
//   border-radius: 25px;  // 设置圆角
//   // padding: 16px;        // 可选：添加内边距
// }